import React, { useState, useEffect, useContext } from 'react'
import { GeneralContext } from '../../context/GeneralContext'
import DataGridNoRows from '../../common/DataGridNoRows'
import { DataGrid } from '@material-ui/data-grid'
import { isEmpty } from 'lodash'
import { Modal, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { InputText } from '../base/controls/InputText'
import TextField from '@material-ui/core/TextField'
import { ButtonControl } from '../base/ButonControl'
import moment from 'moment'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import { getClienteDistancias, getAcciones, getBloquesHorarios, addAgenda, getHistoria, getResultados, generarURLFirmada } from '../../helpers/cambio/apiCalls'

import { DialogAlert } from '../base/DialogAlert';
import { Dialog, DialogContent } from '@material-ui/core';
import Spinner from '../base/Spinner'

export const TableBase = ({ getData, data = [] }) => {

    const { columns } = getData
    const { stateGeneral, setStateGeneral } = useContext(GeneralContext)
    const [modalAcciones, setModalAcciones] = useState([])
    const [distancias, setDistancias] = useState([]);
    const [acciones, setAcciones] = useState([])
    const [bloquesHorarios, setBloquesHorarios] = useState([])
    const [accion, setAccion] = useState(null)
    const [accionAdicional, setAccionAdicional] = useState(null)
    const [estado, setEstado] = useState(null)
    const [observacion, setObservacion] = useState('')
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedHora, setSelectedHora] = useState(null);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openResultados, setOpenResultados] = useState(false);
    const handleOpenResultados = () => setOpenResultados(true);
    const handleCloseResultados = () => setOpenResultados(false);

    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [codeAlert, setCodeAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false)

    const [openDialog, setOpenDialog] = useState(false)
    const [historiaData, setHistoriaData] = useState({ rows: [], columns: [] })

    const [resultados, setResultados] = useState([])
    const [idExterno, setIdExterno] = useState('')

    const handleCloseAlert = () => {
        if (openAlert) {
            setOpenAlert(false)
        }
    }

    const [values, setValues] = useState({
        fecha: moment(new Date()).format("YYYY-MM-DD"),
        accion: 0,
        accionAdicional: 0
    })

    const cargarBloquesHorarios = (idZona, fecha) => {
        setOpenSpinner(true);

        getBloquesHorarios(idZona, fecha)
            .then(({ bloques }) => {
                setOpenSpinner(false);
                setBloquesHorarios(bloques);
            })
            .catch(error => {
                setOpenSpinner(false);
                console.error('Error al cargar bloques horarios', error);
            });
    };

    const handleChangeForm = (name, value) => {
        if (name === "fechaAgendarCambio") {
            sessionStorage.setItem('fechaAgendarCambio', JSON.stringify(moment(value).format("YYYY-MM-DD")))

            setBloquesHorarios([]);
            const zona = isEmpty(JSON.parse(sessionStorage.getItem('zonaAgendarCambio'))) ? modalAcciones.id_zona : JSON.parse(sessionStorage.getItem('zonaAgendarCambio')).id_zona
            getBloquesHorarios(zona, sessionStorage.getItem('fechaAgendarCambio'))
                .then(({ bloques }) => {
                    setOpenSpinner(false);
                    setBloquesHorarios(bloques);
                });
        } else if (name === 'accion') {
            setAccion(value.id_accion);
            setEstado(value.cambio_estado)
            if (value.id_accion != 1) {
                setSelectedValue(null)
                setSelectedHora(null)
            }
        } else if (name === 'observacion') {
            setObservacion(value);
        } else {
            setValues({ ...values, [name]: value });
        }
    }

    const columnOption = {
        field: "📌", headerName: "", width: 10, sortable: false, disableColumnMenu: true, headerAlign: 'center', align: 'center', description: 'Referencia',
        renderCell: (data) => (
            <button onClick={() => setReferencia(data.row)} title="Tomar Referencia">🞖</button>
        )
    }

    const columnAcciones = {
        field: "👤", headerName: "", width: 10, sortable: false, disableColumnMenu: true, headerAlign: 'center', align: 'center', description: 'Cliente',
        renderCell: (modal) => (
            <button onDoubleClick={openModalAcciones} title="Abrir Cliente ¡Doble Click!">☰</button>
        )
    }

    const columnResultados = {
        field: "X", headerName: "", width: 10, sortable: false, disableColumnMenu: true, headerAlign: 'center', align: 'center', description: 'Resultados',
        renderCell: (modal) => (
            <button onDoubleClick={handleVerResultadosClick} title="Abrir Resultados ¡Doble Click!">☰</button>
        )
    }

    const columnDistancia = {
        field: 'distancia', headerName: 'Distancia (km)', width: 160, disableColumnMenu: true, sortable: true, align: 'right',
        renderCell: (params) => (
            <span>{buscarDistancia(params.row.cliente, distancias)}</span>
        ),
    }

    const setReferencia = (referencia) => {
        getClienteDistancias(referencia.cli_latitud, referencia.cli_longitud)
            .then(({ data }) => {
                setDistancias(data);
            })
            .catch((error) => {
                console.error('Error al obtener la distancia:', error);
                setDistancias([]);
            });

    }

    const buscarDistancia = (cliente, distancias) => {
        const distanciaEncontrada = distancias.find((d) => d.cliente === cliente);
        return distanciaEncontrada ? distanciaEncontrada.distancia : "";
    };

    useEffect(() => {
        const zona = isEmpty(JSON.parse(sessionStorage.getItem('zonaAgendarCambio'))) ? modalAcciones.id_zona : JSON.parse(sessionStorage.getItem('zonaAgendarCambio')).id_zona
        cargarBloquesHorarios(zona, sessionStorage.getItem('fechaAgendarCambio'));
    }, [modalAcciones.id_zona, values.fecha]);

    const openModalAcciones = () => {
        handleOpen();
        setAccion(null);
        setObservacion('');
        setSelectedValue(null);

        setOpenSpinner(true);

        getAcciones(1)
            .then(({ acciones }) => {
                setOpenSpinner(false);
                setAcciones(acciones);
            })
            .catch(error => {
                setOpenSpinner(false);
                console.error('Error al cargar acciones', error);
            });
    };

    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: '#F1F1F1'
        },
        bloques_div: {
            maxHeight: 200,
            width: 400,
            overflowY: 'auto',
        },
        modalScroll: {
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '4px',
                height: '4px',
            },
            '&::-webkit-scrollbar-track': {
                background: '#F0F5FF',
            },
            '&::-webkit-scrollbar-thumb:vertical': {
                backgroundImage: 'linear-gradient(180deg, #FCDB00 -0.03%, #95C11F 24.46%, #36B37E 46.33%, #4589FF 63.52%, #1A53BA 80.19%, #24419E 99.98%)',
                borderRadius: '30px',
            },
            '&::-webkit-scrollbar-thumb:horizontal': {
                backgroundImage: 'linear-gradient(90deg, #FCDB00 -0.03%, #95C11F 24.46%, #36B37E 46.33%, #4589FF 63.52%, #1A53BA 80.19%, #24419E 99.98%)',
                borderRadius: '30px',
            },
        },
        listItemRed: {
            color: 'red',
        },
        listItemBlack: {
            color: 'back',
        },
    });

    const classes = useStyles();

    const allColumns = [columnOption, columnAcciones, columnResultados, columnDistancia].concat(columns)
    //allColumns.reverse()

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1100,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const localizedTextsMap = {
        columnMenuUnsort: "Sin orden",
        columnMenuSortAsc: "Orden ascendente",
        columnMenuSortDesc: "Orden desendente",
        columnMenuFilter: "Filtro",
        columnMenuHideColumn: "Ocultar",
        columnMenuShowColumns: "Mostrar columnas"
    }

    const handleCheckboxChange = (value) => {
        setSelectedValue(value.id_bloque_horario);

        const parsedDateTime = moment(value.hora, 'HH:mm:ss Z');
        setSelectedHora(parsedDateTime.format('HH:mm:ss ZZ'));
    };

    const guardar = (e) => {
        e.preventDefault();

        if (accion == null) {
            setMessage("Debe seleccionar una acción para guardar...");
            setCodeAlert(false);
            setOpenAlert(true);
            return;
        } 
        /*
        else if (accion == 1 && selectedValue == null) {
            setMessage("Debe seleccionar un bloque horario para guardar...");
            setCodeAlert(false);
            setOpenAlert(true);
            return;
        }
        

        const val = bloquesHorarios.find(
            (item) => selectedValue == item.id_bloque_horario && item.id_estado != 1
        );

        if (!val || window.confirm(`Este bloque se encuentra ${val.descripcion}. ¿Está seguro que desea agendar de todas maneras?`)) {
            
        }
        */

        guardarRegistro();
    };

    const guardarRegistro = () => {
        setOpenSpinner(true);

        addAgenda({
            id_zona: modalAcciones.id_zona,
            fecha: sessionStorage.getItem('fechaAgendarCambio'),
            hora_inicio: selectedHora,
            id_bloque_horario: selectedValue,
            descripcion: observacion,
            id_cliente: modalAcciones.cliente,
            id_estado: modalAcciones.estado,
            id_estanque: modalAcciones.codigo_tanque,
            ot: modalAcciones.ot,
            id_accion: accion,
            id_gestion: accionAdicional,
            usuario: localStorage.getItem('usuario'),
        }).then(({ message, status }) => {
            setMessage(message === "" ? "Error al guardar" : message);
            setCodeAlert(status === "OK");
            setOpenAlert(true);
            setOpenSpinner(false);

            if (status === "OK") {
                handleClose();
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        });
    };

    const handleVerHistoriaClick = async (ot) => {
        try {
            setOpenSpinner(true);

            const { rows, columns, status, message } = await getHistoria(ot);

            if (status === 'OK' && rows != null) {
                setHistoriaData({ rows, columns });
                setOpenDialog(true); // Abrir el modal después de obtener los datos
            } else {
                setOpenSpinner(false);
                setMessage(message === "" ? "No existe historia para esta OT" : message)
                setCodeAlert(status === "NOK")
                setOpenAlert(true)
                setOpenSpinner(false)
            }
        } catch (error) {
            setOpenSpinner(false);
            console.error('Error obteniendo la historia:', error.message);
        }
    };

    const closeModalHistoria = () => {
        setOpenDialog(false)
        setOpenSpinner(false)
    }

    const handleVerResultadosClick = async () => {
        try {
            handleOpenResultados();
            setOpenSpinner(true);

            getResultados(idExterno)
                .then(({ resultadoAgenda }) => {
                    setOpenSpinner(false);
                    setResultados(resultadoAgenda);
                })
                .catch(error => {
                    setOpenSpinner(false);
                    console.error('Error al cargar los resultados', error);
                });
        } catch (error) {
            setOpenSpinner(false);
            console.error('Error obteniendo los resultados:', error.message);
        }
    };

    function handleClick(link) {
        generarURLFirmada(link)
            .then(({ url }) => {
                window.open(url, '_blank');
            });
    }

    const anular = (ot, id_externo) => {
        addAgenda({
            id_zona: null,
            fecha: null,
            hora_inicio: null,
            id_bloque_horario: null,
            descripcion: 'Anulado desde Resultados',
            id_cliente: null,
            id_estado: 1,
            id_estanque: null,
            ot: ot,
            id_accion: 4,
            id_gestion: null,
            usuario: localStorage.getItem('usuario'),
        }).then(({ message, status }) => {
            setMessage(message === "" ? "Error al guardar" : message);
            setCodeAlert(status === "OK");
            setOpenAlert(true);
            setOpenSpinner(false);

            if (status === "OK") {
                handleClose();
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        });
    }

    const [selectedAccion, setSelectedAccion] = useState(null);
    const handleAutocompleteChange = (event, newValue) => {
        setSelectedAccion(newValue);
        setAccion(newValue.id_accion)
        //getData("accion", !isEmpty(newValue) ? newValue : null);
    };

    const handleAutocompleteGestionChange = (event, newValue) => {
        //setSelectedAccion(newValue);
        setAccionAdicional(newValue)
        //getData("accion", !isEmpty(newValue) ? newValue : null);
    };

    const opcionesAdicionales = [
        { valor: 1, tipo: "Derivado a comercial" },
        { valor: 2, tipo: "Pendiente cubicación/OC" },
        { valor: 3, tipo: "Pendiente modificación por cliente" },
        { valor: 4, tipo: "Pendiente consumo de gas por cliente" },
        { valor: 5, tipo: "Pendiente retiro de gas UDT/Distribución" },
        { valor: 6, tipo: "Pendiente permiso municipal" },
        { valor: 7, tipo: "Pendiente acreditación" },
        { valor: 8, tipo: "Programar" },
    ];

    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={allColumns}
                hideFooter={false}
                components={{
                    NoRowsOverlay: () => DataGridNoRows("no-data"),
                }}
                onCellDoubleClick={(params, event) => {
                    const selectedRowData = params.row
                    if (selectedRowData) {
                        const distClient = buscarDistancia(selectedRowData.cliente, distancias)
                        setModalAcciones({
                            ...selectedRowData,
                            distancia: distClient,
                        });
                    }
                }}
                localeText={localizedTextsMap}
                onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = data.filter((row) => selectedIDs.has(row.id.toString()));

                    if (selectedRowData && selectedRowData.length > 0) {
                        setIdExterno(selectedRowData[0].id_externo)
                    }
                }}
            />

            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                            DM: {modalAcciones.dm} - OT: {modalAcciones.ot} - Cod. Tanque: {modalAcciones.codigo_tanque}
                        </Typography>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            <br></br>
                            <Spinner open={openSpinner} />
                        </Typography>
                        <br></br>
                        <Grid container direction="row" justifyContent="space-between" >
                            <InputText
                                name="nombre"
                                label="Nombre"
                                disabled={true}
                                type="text"
                                defaultValue={modalAcciones.razon_social}
                                widthStyle='long'
                                variant="outlined"
                            />
                            <ButtonControl
                                label="Ver Historia"
                                onClick={() => handleVerHistoriaClick(modalAcciones.ot)}
                            />
                        </Grid>
                        <br></br>
                        <Grid container direction="row">
                            <InputText
                                name="calle"
                                label="Calle"
                                disabled={true}
                                type="text"
                                defaultValue={modalAcciones.calle}
                                widthStyle='long'
                                variant="outlined"
                            />
                            <InputText
                                name="numero"
                                label="Número"
                                disabled={true}
                                type="text"
                                defaultValue={modalAcciones.numero}
                                widthStyle='medium'
                                variant="outlined"
                            />
                        </Grid>
                        <br></br>
                        <Grid container direction="row">
                            <InputText
                                name="comuna"
                                label="Comuna"
                                disabled={true}
                                type="text"
                                defaultValue={`${modalAcciones.comuna}`}
                                widthStyle='medium'
                                variant="outlined"
                            />
                        </Grid>
                        <br></br>
                        <Grid container direction="row">
                            <InputText
                                name="telefono"
                                label="Teléfono 1"
                                disabled={true}
                                type="text"
                                defaultValue={`${modalAcciones.telefono}`}
                                widthStyle='medium'
                                variant="outlined"
                            />
                            <InputText
                                name="telefono"
                                label="Teléfono 2"
                                disabled={true}
                                type="text"
                                defaultValue={`${modalAcciones.telefono2}`}
                                widthStyle='medium'
                                variant="outlined"
                            />
                            <InputText
                                name="correo"
                                label="Correo"
                                disabled={true}
                                type="text"
                                defaultValue={`${modalAcciones.emailc2}`}
                                widthStyle='long'
                                variant="outlined"
                            />
                        </Grid>
                        <br></br><br></br>
                        <Divider />
                        <br></br><br></br>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                            Acciones
                        </Typography>
                        <br></br><br></br>
                        <Grid container direction="row" justifyContent="space-between">
                            <Autocomplete
                                id="size-small-outlined"
                                size="small"
                                options={acciones}
                                getOptionLabel={(option) => option.descripcion || ''}
                                defaultValue={null}
                                onChange={handleAutocompleteChange}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Acción" placeholder="Seleccione Acción" style={{ marginLeft: 8, width: 400 }} />
                                )}
                            />

                            <Grid item>
                                <TextField
                                    disabled={accion != 1 && accion != "8"}
                                    id="fechaAgendar"
                                    label="Fecha"
                                    type="date"
                                    defaultValue={moment(sessionStorage.getItem('fechaAgendarCambio')).format("YYYY-MM-DD")}
                                    className={classes.textField}
                                    onChange={(event) => {
                                        const newValue = event.target.value;
                                        if (!isEmpty(newValue)) {
                                            handleChangeForm("fechaAgendarCambio", newValue);
                                        } else {
                                            sessionStorage.setItem('fechaAgendarCambio', JSON.stringify(moment(new Date()).format("YYYY-MM-DD")))
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <br></br>
                        {selectedAccion && selectedAccion.id_accion === "8" && (
                            <Autocomplete
                                id="size-small-outlined-additional"
                                size="small"
                                options={opcionesAdicionales}
                                getOptionLabel={(option) => option.tipo || ''}
                                defaultValue={null}
                                onChange={handleAutocompleteGestionChange}
                                /*
                                onChange={(event, newValue) => {
                                    handleChangeForm("accionAdicional", !isEmpty(newValue) ? newValue : null);
                                }}
                                */
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Acción Adicional" placeholder="Seleccione Acción Adicional" style={{ marginLeft: 8, width: 400 }} />
                                )}
                            />
                        )}
                        <br></br>
                        <Grid container direction="row" justifyContent="space-between">
                            <TextField
                                id="observacion"
                                label="Observación"
                                multiline
                                rows={4}
                                defaultValue=""
                                variant="outlined"
                                style={{ marginLeft: 8, width: 400 }}
                                onChange={(event) => {
                                    const newValue = event.target.value;

                                    if (!isEmpty(newValue)) {
                                        handleChangeForm("observacion", newValue);
                                    } else {
                                        handleChangeForm("observacion", null);
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            {
                                /*
                            <div className={classes.bloques_div}>
                                <List className={classes.root}>
                                    {bloquesHorarios && bloquesHorarios.map((value) => {
                                        const labelId = `checkbox-list-label-${value.id_bloque_horario}`;

                                        return (
                                            <ListItem
                                                key={value.id_bloque_horario}
                                                role={undefined}
                                                dense
                                                button
                                                onClick={() => handleCheckboxChange(value)}
                                                disabled={accion != 1}
                                                className={value.id_estado != 1 ? classes.listItemRed : classes.listItemBlack}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={selectedValue === value.id_bloque_horario}
                                                        onChange={() => handleCheckboxChange(value)}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                        color='#000000'
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={`${value.hora}`} />
                                                <ListItemText id={labelId} primary={`${value.descripcion}`} />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </div>
                            */
                        }
                        </Grid>
                        <br></br>
                        <Typography id="modal-modal-title" variant="h6" component="h6" align='right'>
                            Distancia: {modalAcciones.distancia}
                        </Typography>
                        <br></br><br></br>
                        <Divider />
                        <br></br><br></br>
                        <Grid container direction="row" justifyContent="space-evenly" alignItems="center" >
                            <ButtonControl
                                label="Agregar"
                                onClick={guardar}
                            />
                        </Grid>
                    </Box>
                </Modal>
                <Dialog open={openDialog} onClose={() => closeModalHistoria()} maxWidth="lg" fullWidth>
                    <DialogContent>
                        <br></br>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                            Historia Cambio y Gestión de OT {modalAcciones.ot}
                        </Typography>
                        <br></br><br></br>
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={historiaData.rows}
                                columns={historiaData.columns}
                                pageSize={5}
                                rowsPerPageOptions={[5, 10, 20]}
                                pagination
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>

            <div>
                <Modal
                    open={openResultados}
                    onClose={handleCloseResultados}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        height: 800,
                        overflowY: 'auto',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4
                    }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                            <b>Resultado de la visita</b>
                        </Typography>
                        <br></br>
                        <br></br>
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {!resultados ? <span> No existen resultados para esta visita</span> : resultados.map((item, index) => (
                                <li key={index}>
                                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                                        <li><span style={{ paddingRight: 10 }}><b>objectid</b>:</span> <span>{item.objectid}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>dm</b>:</span> <span>{item.dm}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>motivo</b>:</span> <span>{item.motivo}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>tipo_tanque</b>:</span> <span>{item.tipo_tanque}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>capacidad_tanque</b>:</span> <span>{item.capacidad_tanque}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>codigo_barra</b>:</span> <span>{item.codigo_barra}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>serie</b>:</span> <span>{item.serie}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>fecha_f</b>:</span> <span>{item.fecha_f}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>fecha_u</b>:</span> <span>{item.fecha_u}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>empresa_inspectora</b>:</span> <span>{item.empresa_inspectora}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>empresa_certi</b>:</span> <span>{item.empresa_certi}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>numero_certifi</b>:</span> <span>{item.numero_certifi}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>porcentaje</b>:</span> <span>{item.porcentaje}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>codigo_estanque</b>:</span> <span>{item.codigo_estanque}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>tipo_reg</b>:</span> <span>{item.tipo_reg}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>marca_reg</b>:</span> <span>{item.marca_reg}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>modelo_reg</b>:</span> <span>{item.modelo_reg}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>tipo_camion</b>:</span> <span>{item.tipo_camion}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>distancia_camion</b>:</span> <span>{item.distancia_camion}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>llave</b>:</span> <span>{item.llave}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>obs1</b>:</span> <span>{item.obs1}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>obs2</b>:</span> <span>{item.obs2}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>referencia</b>:</span> <span>{item.referencia}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>nombre_tecnico</b>:</span> <span>{item.nombre_tecnico}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>empresa_contra</b>:</span> <span>{item.empresa_contra}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>nombre_cliente</b>:</span> <span>{item.nombre_cliente}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>cargo_cliente</b>:</span> <span>{item.cargo_cliente}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>rut_cliente</b>:</span> <span>{item.rut_cliente}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>telefono</b>:</span> <span>{item.telefono}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>mail</b>:</span> <span>{item.mail}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>check_lock</b>:</span> <span>{item.check_lock}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>problemas_acceso</b>:</span> <span>{item.problemas_acceso}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>complicacion_camion</b>:</span> <span>{item.complicacion_camion}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>rep_pasto</b>:</span> <span>{item.rep_pasto}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>rep_bases</b>:</span> <span>{item.rep_bases}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>resultado_visita</b>:</span> <span>{item.resultado_visita}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>globalid</b>:</span> <span>{item.globalid}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>created_user</b>:</span> <span>{item.created_user}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>created_date</b>:</span> <span>{item.created_date}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>last_edited_user</b>:</span> <span>{item.last_edited_user}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>last_edited_date</b>:</span> <span>{item.last_edited_date}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>listo_para_cambio</b>:</span> <span>{item.listo_para_cambio}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>capacidad_otra</b>:</span> <span>{item.capacidad_otra}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>ot</b>:</span> <span>{item.ot}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>id_externo</b>:</span> <span>{item.id_externo}</span></li>
                                        <li><span style={{ paddingRight: 10 }}><b>Estado Agenda</b>:</span> <span>{item.id_estado}</span></li>

                                        {item.links_privados && item.links_privados.length > 0 && (
                                            <li>
                                                <b>Fotos:</b>
                                                <ul>
                                                    {item.links_privados.map((link, linkIndex) => (
                                                        <li key={linkIndex}>
                                                            <a href="#" onClick={() => handleClick(link)}>Imagen {linkIndex + 1}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        )}
                                    </ul>
                                    <div style={{ marginTop: 20 }}>
                                        <Button onClick={handleCloseResultados} variant="contained" color="primary" style={{ marginRight: 8 }}>Cerrar</Button>
                                        <Button onClick={() => anular(item.ot, item.id_externo)} variant="contained" color="primary" disabled={item.id_estado !== '10' ? true : false}>Anular</Button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </Box>
                </Modal>
            </div>

            {openAlert &&
                <DialogAlert
                    title="Alerta"
                    message={message}
                    type="alert"
                    code={codeAlert}
                    method="DELETE"
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                />
            }
            <Spinner open={openSpinner} />

            <div style={{
                paddingTop: 30,
                backgroundColor:
                    'white',
                display: 'flex',
                flexDirection: 'row-reverse'
            }}
            >
            </div>
        </div>
    )
}

